<template>
  <a
    class="flix-btn flix-btn-save"
    href="#"
    @click.prevent="setSave"
    v-html="title"
  ></a>
</template>
<script>
export default {
  components: {},
  props: {
    callback: Function,
    label: String
  },
  data() {
    return {
      title: this.label || this.$t("default.continue")
    };
  },
  computed: {},
  mounted() {
    this.$store.commit("save/setSave", false);
  },
  methods: {
    setSave() {
      this.$store.commit("save/setSave", true);
      this.callback();
    }
  }
};
</script>
<style lang="sass" scoped></style>
